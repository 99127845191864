.Photography {
    position: relative;
    width: 100%;
}

.Photography-sticky {
    position: sticky;
    display: flex;
    top: 0;
    height: 100vh;
    width: 100%;
    overflow-x: hidden;
}

.Photography-horizontal {
    align-self: center;
    top: 0;
    will-change: transform;
}

.Photography-row {
    display: flex;
    flex-direction: row;
}

.Photography-image {
}

.red {
    background-color: red;
    height: 100px;
}
