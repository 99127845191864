.Reinforcement {
    display: flex;
    flex-direction: column;
    align-self: center;
    margin: 0 auto;
}

.Reinforcement > div {
    display: flex;
    flex-direction: row;
}

.Reinforcement > div > div {
    border-style: solid;
    border: 0px solid #eee;
    text-align: center;
}
