.Login {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.Login button, .Login input {
    margin: 0 10px;
    padding: 10px;
    font-size: 16px;
}

.Login input[type='password'] {
    font-family: Verdana;
}

.Login button {
    background-color: magenta;
    color: white;
    border: none;
    cursor: pointer;
}
