.Navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 100;
}

.Navigation ul {
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 10px 0;
}

.Navigation li {
  flex: 1;
  text-align: center;
}

.nav-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #333;
  transition: color 0.3s;
}

.nav-link i {
  font-size: 24px;
  margin-bottom: 5px;
}

.nav-link span {
  font-size: 12px;
}

.nav-link:hover,
.nav-link.active {
  color: #007bff;
}