.Menu {
    transition: 0.5s;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    z-index: 100;
    background-color: transparent;
    padding-left: 25pt;
    list-style: none;
}

.Menu a {
    font-weight: bold;
    cursor: pointer;
    font-size: 90px;
    color: inherit;
    text-decoration: inherit;
    text-transform: capitalize;
}
