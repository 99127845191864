body {
    font-family: 'LubalinGraphStd-Medium', serif;
    text-transform: capitalize;
}

.Home {
    display: flex;
    flex-direction: column;
}

.Home-slider {
}

.Background {
    height: 100vh;
    width: 100vw;
    display: flex;
    position: fixed;
    justify-content: center;
}


.Description {
    z-index: 10;
    padding: 0 5%;
    background-color: rgba(255, 255, 255, .7);
}

.Description p {
    color: black;
    font-family: Arial, sans-serif;
    font-size: 16px;

}