.Presentation {
    align-self: center;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    text-transform: capitalize;
}

@media only screen and (min-width: 600px) {
    .Presentation {
        font-size: 72px;
    }
}
