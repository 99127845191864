.If {
    position: absolute;
    font-size: 24px;
    z-index: 1;
    mix-blend-mode: normal;
    font-family: Futura;
    font-weight: normal;
}

.Then {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    font-weight: bold;
    z-index: 0;
    color: blue;
}

.Then .name {
    font-size: 130px;
    align-self: center;
    z-index: 0;
    cursor: auto;
}

@media only screen and (min-width: 600px) {
    .Then .name {
        font-size: 360px;
    }
}
