@import url('//hello.myfonts.net/count/3bfee5');

* {
    margin: 0;
    padding: 0;
}

body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

@font-face {
    font-family: 'LubalinGraphStd-Medium';
    src: url('fonts/LubalinGraphStd-Medium/font.woff2') format('woff2'),
        url('fonts/LubalinGraphStd-Medium/font.woff') format('woff');
}
