.Column {
    max-width: 1080px;
    margin: auto;
}

.AnaglyphContainer {
    overflow: hidden;
}

.AnaglyphContainer img {
    width: 100%;
    display: block;
}

.Anaglyph {
    position: relative;
}

.AnaglyphLeft {
    filter: url(#red);
}
.AnaglyphRight {
    position: absolute;
    filter: url(#cyan);
    mix-blend-mode: lighten;
    inset: 0;
}

.WigglegramContainer {
    overflow: hidden;
}

.Wigglegram {
    animation: fade 0.25s infinite;
    animation-timing-function: steps(2);
    display: flex;
    width: 200%;
}

.WigglegramLeft, .WigglegramRight {
    display: block;
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
}

@keyframes fade {
    100% {
        transform: translateX(-100%);
    }
}

.SideBySide {

}

.SideBySideLeft, .SideBySideRight {
    width: 50%;
}

p.thick {
    font-weight: bold;
    font-size: 12px;
}

.right {
    text-align: right;
}

.noPadding {
    padding-bottom: 0 !important;
}

.signature {
    font-size: 16px !important;
    font-family: 'LubalinGraphStd-Medium', serif;
    color: magenta !important;
}
