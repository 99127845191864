.Flyer {

}

.Background {
    height: 100vh;
    width: 100vw;
    display: flex;
    position: absolute;
    justify-content: center;
    overflow: hidden;
}

.Flowers {
    align-self: center;
    margin: 0 auto;
    opacity: 0.3;
    
}

.Main {
    font-size: 19px;
}

.Main a:link {
    color: blue;
}

.Main a:visited {
    color: blue;
}

.Main a:visited {
    color: blue;
}

.Info {
    padding: 30px;
    position: absolute;
    z-index: 2;
}

.Info h1 {
    font-size: 64px;
    text-align: center;
}

.Info p {
    font-family: serif;
    padding-top: 15px;
    text-transform: none;    
}

.Info a {
    color: black;
}

.Block {
    padding-top: 20px;
}

.address p {
    padding-top: 0px;
}

.Url a {
    text-transform: capitalize;
}

.emphasis {
    font-weight: bold;
    font-size: 20px;
    font-family: 'LubalinGraphStd-Medium', serif !important;
}

.right {
    text-align: right;
}

@media only screen and (min-width: 600px) {
    .Main {
        font-size: 60px;
    }

    .Info h1 {
        font-size: 120px;
    }

    .Info {
        padding: 50px;
    }

    .Info p {
        font-size: 24px;
    }
    
}