.Bolero {
    align-self: center;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: white;
}

@media only screen and (min-width: 600px) {
    .Bolero {
        font-size: 96px;
    }
}
